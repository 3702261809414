import React from 'react';
import './App.css';
import PrivacyRequest from './components/pages/PrivacyRequest';

function App() {
	return (
		<PrivacyRequest />
	);
}

export default App;
