import React from 'react'
import { Col, Button, Form, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import ReCAPTCHA from "react-google-recaptcha";
import '../../Privacy.css';
class PrivacyRequest extends React.Component {
    constructor(props) {
        super(props);
        this.url = process.env.REACT_APP_URL
        this.state = {
            siteKey: process.env.REACT_APP_RECAPTCHA_KEY,
            captchaWasHit: false,
            firstName: "",
            lastName: "",
            companyNames: [
                "SILAC Insurance Company",
                "SILAC Life Insurance Company",
                "Sterling Investors Life Insurance Company",
            ],
            relationship: "",
            relationshipCompany: "",
            relationshipNames: [
                "Policy Holder",
                "Annuitant",
                "Beneficiary",
                "Agent",
                "Applicant",
                "Former Policy Holder",
                "Former Applicant for Insurance",
                "Former Agent",
                "Power of Attorney",
            ],
            request: "",
            contactType: "",
            contactTypeNames: [
                "Email",
                "Phone",
                "Mail",
            ],
            email: "",
            phone: "",
            street: "",
            street2: "",
            city: "",
            state: "",
            postal: "",
            policyNumber: "",
            agentNumber: "",
            isVisible: {},
            defaultSelect: { contact: true, company: true, relationship: true },
            validate: {},
            successMessage: ''


        }    
        this.handleChange = this.handleChange.bind(this);
        this.recaptchaRef = React.createRef();
    }

    /**
     * Sets the state of when a value is changed. 
     * @param {event} event - the event of the changed value
     */
    async handleChange(event) {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;
        await this.setState({
            [name]: value,
        });
    }

    /**
     * Sets the state of when the contact method is changed. 
     * @param {event} event - the event of the changed value
     */
    async handleContactChange(event) {
        await this.handleChange(event);
        var { isVisible, contactType, defaultSelect } = this.state;
        isVisible.email = contactType === "Email";
        isVisible.phone = contactType === "Phone";
        isVisible.mail = contactType === "Mail";
        defaultSelect.contact = false;

        this.setState({ isVisible, defaultSelect });
    }

    /**
     * 
     * @param {event} event - the event of the changed value
     */
    async handleRecaptchaChange(event) {
        var { captchaWasHit } = this.state;
        captchaWasHit = true;
        this.setState({ captchaWasHit });
    }

    /**
     * Returns a boolean that the phone number stored in state is a simple 10-digit number
     */
    isValidPhoneNumber() {
        const phoneRex = /^\d{10}$/;
        const { validate, phone } = this.state;
        const isValid = phoneRex.test(phone);
        if (isValid) {
            validate.phoneNumberState = 'has-success';
        } else {
            validate.phoneNumberState = 'has-danger';
        }
        this.setState({ validate });
        return isValid;
    }

    /**
     * Returns a boolean that the value is not empty or just white space.
     * @param {string} value - string to be checked 
     */
    isNotEmpty(value) {
        var notEmpty = value.length !== 0 && value.trim();
        return notEmpty;
    }

    /**
     * Determines if the value is not empty.
     * @param {value} value 
     */
    isSelected(value) {
        var selection = value !== "";
        return selection;
    }

    /**
     * Returns a boolean that the value is alphanumeric.
     */
    isAlphaNumeric(value) {
        const valueRex = /^[a-zA-Z0-9]*$/;
        const isValid = valueRex.test(value);
        return isValid;
    }

    /**
     * Returns a boolean that the email stored in state is a valid email.
     */
    isValidEmail() {
        //General Email Regex (RFC 5322 Official Standard)
        const emailRex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:|\\)*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:|\\)+)\])/;
        const { validate, email } = this.state;
        const isValid = emailRex.test(email);
        if (isValid) {
            validate.emailState = 'has-success';
        } else {
            validate.emailState = 'has-danger';
        }
        this.setState({ validate });
        return isValid;
    }

    /**
     * Returns a boolean that the street address stored in state is a valid US address
     */
    isValidStreetAddress() {
        const { street, state, city, postal, validate } = this.state
        var valid = true;
        if (this.isNotEmpty(street)) {
            validate.streetState = "has-success";
        }
        else {
            validate.streetState = "has-danger";
            valid = false;
        }
        if (this.isNotEmpty(state)) {
            validate.stateState = "has-success";
        }
        else {
            validate.stateState = "has-danger";
            valid = false;
        }
        if (this.isNotEmpty(city)) {
            validate.cityState = "has-success";
        }
        else {
            validate.cityState = "has-danger";
            valid = false;
        }
        if (this.isNotEmpty(postal)) {
            validate.postalState = "has-success";
        }
        else {
            validate.postalState = "has-danger";
            valid = false;
        }
        return valid;
    }

    /**
     * Returns a boolean that the form data is valid
     */
    validation() {
        const { firstName, lastName, validate, relationshipCompany, request, relationship, contactType, policyNumber, agentNumber, captchaWasHit } = this.state
        var valid = true;
        if (captchaWasHit) {
            validate.captchaState = "has-success";
        }
        else {
            validate.captchaState = "has-danger";
            valid = false;
        }
        if (this.isNotEmpty(firstName)) {
            validate.firstNameState = "has-success";
        }
        else {
            validate.firstNameState = "has-danger";
            valid = false;
        }
        if (this.isNotEmpty(lastName)) {
            validate.lastNameState = "has-success";
        }
        else {
            validate.lastNameState = "has-danger";
            valid = false;
        }
        if (this.isNotEmpty(request)) {
            validate.requestState = "has-success";
        }
        else {
            validate.requestState = "has-danger";
            valid = false;
        }
        if (this.isSelected(relationshipCompany)) {
            validate.relationshipCompanyState = "has-success";
        }
        else {
            validate.relationshipCompanyState = "has-danger";
            valid = false;
        }
        if (this.isSelected(relationship)) {
            validate.relationshipState = "has-success";
        }
        else {
            validate.relationshipState = "has-danger";
            valid = false;
        }
        if (this.isSelected(contactType)) {
            validate.contactTypeState = "has-success";
        }
        else {
            validate.contactTypeState = "has-danger";
            valid = false;
        }
        if (this.isAlphaNumeric(policyNumber)) {
            validate.policyState = "has-success";
        }
        else {
            validate.policyState = "has-danger";
            valid = false;
        }
        if (this.isAlphaNumeric(agentNumber)) {
            validate.agentState = "has-success";
        }
        else {
            validate.agentState = "has-danger";
            valid = false;
        }
        switch (contactType) {
            case "Phone":
                valid = this.isValidPhoneNumber() && valid;
                break;
            case "Email":
                valid = this.isValidEmail() && valid;
                break;
            case "Mail":
                valid = this.isValidStreetAddress() && valid;
                break;
            default:
                break;
        }


        this.setState({ validate });
        return valid;
    }

    /**
     * Resets all of the state data so that the form is clear
     */
    refreshForm() {
        this.setState({
            captchaWasHit: false,
            firstName: "",
            lastName: "",
            relationship: "",
            relationshipCompany: "",
            request: "",
            contactType: "",
            email: "",
            phone: "",
            street: "",
            street2: "",
            city: "",
            state: "",
            postal: "",
            policyNumber: "",
            agentNumber: "",
            isVisible: {},
            defaultSelect: { contact: true, company: true, relationship: true },
            validate: {}
        });
        this.recaptchaRef.current.reset();
    }

    /**
    * Handles the api call and response. 
    * @param {FormData} formData
    */
    sendRequest(formData) {
        var md5 = require('md5');
        const timestamp = Date.now();
        formData.append( "timestamp", timestamp )
        const key = md5(`Neumont Intern Team 2020${timestamp}`);

        // key will be HTTP Basic Authentication header
        fetch(this.url, {
            'Content-Type': 'multipart/form-data',
            method: 'POST',
            headers:  new Headers({
                "X-Key": key
              }),
            body: formData,
        })
            .then((response) => {
                console.log(formData)
                if (response.ok) {
                    var { successMessage } = this.state;
                    successMessage = `You successfully submited your request.`;
                    console.log(response)
                    this.refreshForm();
                    this.setState({ successMessage, });
                    console.log('Success:', response.json());
                }
                else {
                    throw Error(response.statusText);
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }

    /**
    * Handles the submission of a form and validation. 
    * @param {event} event
    */
    submitForm(event) {
        event.preventDefault();
        if (this.validation()) {
            const { firstName, lastName, relationship, relationshipCompany, request, contactType, policyNumber, agentNumber } = this.state;
            let formData = new FormData();
            formData.append('firstName', firstName);
            formData.append('lastName', lastName);
            formData.append('relationship', relationship);
            formData.append('relationshipCompany', relationshipCompany);
            formData.append('request', request);
            formData.append('contactType', contactType);
            switch (contactType) {
                case "Phone":
                    const { phone } = this.state;
                    formData.append('phone', phone);
                    break;
                case "Email":
                    const { email } = this.state;
                    formData.append('email', email);
                    break;
                case "Mail":
                    const { street, street2, city, state, postal } = this.state;
                    formData.append('street', street);
                    formData.append('street2', street2);
                    formData.append('city', city);
                    formData.append('state', state);
                    formData.append('postal', postal);
                    break;
                default:
                    break;
            }
            if (policyNumber !== null && policyNumber !== '') {
                formData.append('policyNumber', policyNumber);
            }
            if (agentNumber !== null && agentNumber !== '') {
                formData.append('agentNumber', agentNumber);
            }
            this.sendRequest(formData);
        }
    }

    render() {
        const { firstName, lastName, companyNames, relationship, relationshipCompany, relationshipNames, request, contactType, contactTypeNames,
            email, phone, street, street2, city, state, postal, policyNumber, agentNumber, isVisible, defaultSelect, validate } = this.state;
        return (
            <Form className='form' onSubmit={(e) => this.submitForm(e)} id="ccpaForm">
                <h2>Privacy Request Form</h2>
                <p className='warningMessage'><b>PLEASE DO NOT PROVIDE ANY UNNECESSARY SENSITIVE INFORMATION WITHIN THIS REQUEST, SUCH AS SOCIAL SECURITY NUMBER, PASSWORD, ETC.</b></p>
                <FormGroup row classname='form-group-name'>
                    <Label  className='formLabel' for="firstName">First Name</Label>
                    <Col >
                        <Input type="text" name="firstName" id="firstName" placeholder="John" value={firstName} onChange={this.handleChange}
                            valid={validate.firstNameState === 'has-success'}
                            invalid={validate.firstNameState === 'has-danger'} />
                        <FormFeedback invalid>First name is required.</FormFeedback>
                    </Col>
                    <Label  className='formLabel' for="lastName">Last Name</Label>
                    <Col >
                        <Input type="text" name="lastName" id="lastName" placeholder="Doe" value={lastName} onChange={this.handleChange}
                            valid={validate.lastNameState === 'has-success'}
                            invalid={validate.lastNameState === 'has-danger'} />
                        <FormFeedback invalid>Last name is required.</FormFeedback>
                    </Col>
                </FormGroup>

                <FormGroup row>
                    <Label  className='formLabel' for="relationshipCompany">Which company do you have a relationship with?</Label>
                    <Col >
                        <Input type="select" name="relationshipCompany" id="relationshipCompany" value={relationshipCompany} onChange={(e) => {
                            this.handleChange(e);
                            defaultSelect.company = false;
                            this.setState({ defaultSelect })
                        }}
                            valid={validate.relationshipCompanyState === 'has-success'}
                            invalid={validate.relationshipCompanyState === 'has-danger'}>
                            <option disabled={!defaultSelect.company}>Please Select One</option>
                            {companyNames.map((value) => {
                                return <option key={value}>{value}</option>
                            })}
                        </Input>
                        <FormFeedback invalid>Must select a relationship company.</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label  className='formLabel' for="relationship">What is the relationship?</Label>
                    <Col >
                        <Input type="select" name="relationship" id="relationship" value={relationship} onChange={(e) => {
                            this.handleChange(e);
                            defaultSelect.relationship = false;
                            this.setState({ defaultSelect })
                        }}
                            valid={validate.relationshipState === 'has-success'}
                            invalid={validate.relationshipState === 'has-danger'}>
                            <option value="" disabled={!defaultSelect.relationship}>Please Select One</option>
                            {relationshipNames.map((value) => {
                                return <option key={value}>{value}</option>
                            })}
                        </Input>
                        <FormFeedback invalid>Must select a relationship.</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label className='formLabel' for="request">Request:</Label>
                    <Col >
                        <Input type="textarea" name="request" id="request" value={request} onChange={this.handleChange} className='request'
                            valid={validate.requestState === 'has-success'}
                            invalid={validate.requestState === 'has-danger'} />
                        <FormFeedback invalid>Descriptive request is required.</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label  className='formLabel' for="contactType">Preferred method of contact</Label>
                    <Col >
                        <Input type="select" name="contactType" id="contactType" value={contactType} onChange={(e) => this.handleContactChange(e)}
                            valid={validate.contactTypeState === 'has-success'}
                            invalid={validate.contactTypeState === 'has-danger'}>
                            <option disabled={!defaultSelect.contact}>Please Select One</option>
                            {contactTypeNames.map((value) => {
                                return <option key={value}>{value}</option>
                            })}
                        </Input>
                        <FormFeedback invalid>Must select a preferred method of contact.</FormFeedback>
                    </Col>
                </FormGroup>
                {isVisible.email ? <FormGroup row>
                    <Label  className='formLabel' for="email">Email:</Label>
                    <Col >
                        <Input type="text" name="email" id="email" value={email} onChange={this.handleChange}
                            valid={validate.emailState === 'has-success'}
                            invalid={validate.emailState === 'has-danger'} />
                        <FormFeedback invalid>Incorrect email format.</FormFeedback>
                    </Col>
                </FormGroup> : null}
                {isVisible.phone ? <FormGroup row>
                    <Label  className='formLabel' for="phone">Phone:</Label>
                    <Col >
                        <Input type="text" name="phone" id="phone" value={phone} onChange={this.handleChange}
                            valid={validate.phoneNumberState === 'has-success'}
                            invalid={validate.phoneNumberState === 'has-danger'} />
                        <FormFeedback invalid>Must input a simple 10 digit phone number (1234567890)</FormFeedback>
                    </Col>
                </FormGroup> : null}
                {isVisible.mail ? <div>
                    <FormGroup row>
                        <Label className='formLabel' for="street">Street:</Label>
                        <Col >
                            <Input type="text" name="street" id="street" value={street} onChange={this.handleChange}
                                valid={validate.streetState === 'has-success'}
                                invalid={validate.streetState === 'has-danger'} />
                            <FormFeedback invalid>Must include a street address</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label  className='formLabel' for="street2">Street 2:</Label>
                        <Col >
                            <Input type="text" name="street2" id="street2" value={street2} onChange={this.handleChange} />
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label className='formLabel' for="city">City:</Label>
                        <Col >
                            <Input type="text" name="city" id="city" value={city} onChange={this.handleChange}
                                valid={validate.cityState === 'has-success'}
                                invalid={validate.cityState === 'has-danger'} />
                            <FormFeedback invalid>City name is required.</FormFeedback>
                        </Col>
                        <Label className='formLabel' for="state">State:</Label>
                        <Col >
                            <Input type="text" name="state" id="state" value={state} onChange={this.handleChange}
                                valid={validate.stateState === 'has-success'}
                                invalid={validate.stateState === 'has-danger'} />
                            <FormFeedback invalid>State is required.</FormFeedback>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label className='formLabel' for="postal">Postal Code:</Label>
                        <Col >
                            <Input type="text" name="postal" id="postal" value={postal} onChange={this.handleChange}
                                valid={validate.postalState === 'has-success'}
                                invalid={validate.postalState === 'has-danger'} />
                            <FormFeedback invalid>Postal code is required.</FormFeedback>
                        </Col>
                    </FormGroup>
                </div> : null}
                <FormGroup row>
                    <Label className='formLabel' for="policyNumber">Policy Number (Optional):</Label>
                    <Col >
                        <Input type="text" name="policyNumber" id="policyNumber" value={policyNumber} onChange={this.handleChange}
                            valid={validate.policyState === 'has-success'}
                            invalid={validate.policyState === 'has-danger'} />
                        <FormFeedback invalid>Policy Number must only include numbers and letters</FormFeedback>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label className='formLabel' for="agentNumber">Agent Number (Optional):</Label>
                    <Col >
                        <Input type="text" name="agentNumber" id="agentNumber" value={agentNumber} onChange={this.handleChange}
                            valid={validate.agentState === 'has-success'}
                            invalid={validate.agentState === 'has-danger'} />
                        <FormFeedback invalid>Agent Number must only include numbers and letters</FormFeedback>
                    </Col>
                </FormGroup>
                <ReCAPTCHA className='captcha'
                    ref={this.recaptchaRef}
                    sitekey={this.state.siteKey}
                    onChange={(e) => this.handleRecaptchaChange(e)}
                    valid={validate.captchaState === 'has-success'}
                    invalid={validate.captchaState === 'has-danger'} />
                <Button className='submitButton'>Submit</Button>
                <Label  className='message'>{this.state.successMessage}</Label>
            </Form>
        )

    }
}



export default PrivacyRequest;